import React from 'react';
import cn from 'classnames';

export const Button: React.FC<{
    label: string;
    type?: any;
    disabled?: boolean;
    onClick?: any;
    className?: string;
}> = ({ label, ...props }) => (
    <button
        {...props}
        className={cn(
            'bg-magic-purple-light py-2 px-8 text-sm text-white font-bold rounded-3xl cursor-pointer',
            { 'cursor-not-allowed opacity-50': props.disabled },
            props.className
        )}
    >
        {label}
    </button>
);
