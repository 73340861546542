import { useRouter } from 'next/dist/client/router';
import { useState } from 'react';
import cn from 'classnames';
import { Button } from '../src/components/button';
import { Card } from '../src/components/card';
import { Input } from '../src/components/input';
import { Link } from '../src/components/link';
import toast from 'react-hot-toast';
import { useUser } from '../src/hooks/user/user';

const LoginPage = () => {
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { loading, login } = useUser();

    return (
        <div className="flex p-4 md:my-auto">
            <Card className="max-w-2xl" loading={loading}>
                <p className="text-2xl font-bold text-magic-purple">Login</p>
                <form
                    className={cn('flex w-full flex-1 flex-col space-y-8', {
                        'opacity-75': loading
                    })}
                    onSubmitCapture={async (e) => {
                        e.preventDefault();

                        try {
                            await login(email, password);
                            router.push('/');
                            toast.success('Succesfully logged in');
                        } catch (e: any) {
                            toast.error(e.message || 'Ooops! Something went wrong.');
                        }
                    }}
                >
                    <Input
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="jane@getmagic.com"
                        disabled={loading}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                    <Input
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="********"
                        disabled={loading}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-magic-purple-faded">
                        Forgot password? <Link href="/forgot-password" label={'Reset password'} />
                    </p>
                    <div
                        className={cn('mt-8 flex flex-1 justify-end', { 'animate-pulse': loading })}
                    >
                        <Button
                            data-testid="LoginButton"
                            label="LOGIN"
                            type="submit"
                            disabled={loading}
                        />
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default LoginPage;
