import React from 'react';
import cn from 'classnames';

export const Input: React.FC<{
    label?: string;
    placeholder?: string;
    type: string;
    value?: any;
    disabled?: any;
    name?: string;
    inline?: boolean;
    onChange?: (e: any) => void;
    onKeyDown?: (e: any) => void;
}> = ({ label, inline = false, ...props }) => (
    <label className={cn('flex w-full', { 'items-center': inline, 'flex-col space-y-4': !inline })}>
        {label && <div className="text-magic-purple-faded font-bold">{label}</div>}
        <input
            className={cn('text-magic-purple bg-white rounded border-2 p-2 w-full', {
                'ml-2': inline && label
            })}
            {...props}
        />
    </label>
);
